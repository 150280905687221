import { Link } from "gatsby"
import React from "react"
import logo from "../../static/logo.png"
import headerStyles from "./header.module.scss"

export default function Header({ children }) {
    const [isActive, setIsActive] = React.useState(false);
    return (
        <header className="section pb-1">
            <div className="container is-max-desktop">
                <nav className="navbar has-shadow is-transparent" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <img src={logo} alt="Amit" width="150" height="400" />
                        </Link>
                        <a
                            onClick={() => {
                                setIsActive(!isActive);
                            }}
                            role="button"
                            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
                            aria-label="menu"
                            aria-expanded="false"
                            data-target="navbarBasicExample"
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div
                        id="navbarBasicExample"
                        className={`navbar-menu ${isActive ? "is-active" : ""}`}
                    >
                        <div className="navbar-start">
                            <div className="navbar-item">
                                <Link
                                    style={{ fontSize: "1.25rem" }}
                                    to="/"
                                    className="navbar-item"
                                    activeClassName={headerStyles.active}
                                >
                                    Home
                                </Link>
                            </div>
                            <div className="navbar-item">
                                <Link
                                    style={{ fontSize: "1.25rem" }}
                                    to="/blog"
                                    className="navbar-item"
                                    activeClassName={headerStyles.active}
                                >
                                    Blog
                                </Link>
                            </div>
                            <div className="navbar-item">
                                <a
                                    className={`${headerStyles.link} ml-3 mr-3`}
                                    href="https://www.linkedin.com/in/amit-malhotra-134b0132/" target="_blank"
                                    rel="noreferrer"
                                >
                                    LinkedIn
                                </a>
                            </div>
                            <div className="navbar-item">
                                <a
                                    className={`${headerStyles.link} ml-3 `}
                                    href="https://github.com/malhotraamit236" target="_blank"
                                    rel="noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}